import * as React from "react";

// Hero Media

export const HeroMedia = () => {
  return (
    <img className="media bkg" src="/images/after-an-hiv-test/after-test-hero.png" alt="Man at barbershop" />
  )
}

// Hero Call To Action

export const Cta = (props) => {
  return (
    <>
      <h1>
        Getting Your Result: Taking the Next Step
      </h1>
      <p>
        Knowing your HIV status is key to understanding what to do next. No matter your 
        test result, there are options for you. Talk to a healthcare professional about what is right for you.
      </p>
      <div
        onClick={() => { props.scrollToElement("nav-anchor-understanding-your-results", -130, -435) }}
        className="scroll below-buttons" role="button">
        <p className="copy">
          SCROLL
        </p>
        <img className="icon-scroll" src="/images/global/play-icon.svg" />
      </div>
    </>
  )
} 
