import * as React from 'react';
import Bar from '../global/Bar.js';

const KnowYourOptions = () => {
  return (
    <>
      <section
        id="nav-anchor-after-an-hiv-test"
        className="know-your-options container-fluid where-to-get-tested bkg-white"
      >
        <div className="container px-0">
          <div className="row callouts">
            <div className="col-12">
              <h2>
                Know Your Options
              </h2>
            </div>
            <div className="col-12 col-lg-6 container-know-your-options">
              <div className="content prevention">
                <img
                  className="icon d-none d-md-block"
                  src='/images/after-an-hiv-test/Help_Prevent_HIV_icon.svg'
                  alt=""
                />
                <img
                  className="icon d-block d-md-none"
                  src="/images/after-an-hiv-test/Help_Prevent_HIV_icon.svg"
                  alt=""
                />
                <h3>
                  How to Prevent HIV
                </h3>
                <p className="description">
                  When it comes to lowering your chances of getting HIV, you have options. This includes using condoms 
                  with lube, safer sex practices, not sharing needles, and taking PrEP medications. The Centers for Disease Control 
                  and Prevention (CDC) recommends all sexually active adults and 
                  adolescents know about PrEP. Talk to a healthcare professional to see if PrEP is right for&nbsp;you.
                </p>
                <p className="description">
                  PrEP (pre-exposure prophylaxis) is medicine that reduces your chances of getting HIV from 
                  sex. There are different PrEP medication options available, some of which 
                  can be about 99% effective in reducing the chances of getting HIV when taken as prescribed. 
                </p>
                <a
                  target="_blank"
                  className="button transparent bdr-blue thin"
                  href="https://www.healthysexuals.com/stis#prevention"
                >
                  LEARN MORE
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-6 container-know-your-options">
              <div className="content treatment">
                <img
                  className="icon d-none d-md-block"
                  src='../../images/after-an-hiv-test/Help_Treat_HIV_icon.svg'
                  alt=""
                />
                <img
                  className="icon d-block d-md-none"
                  src="/images/after-an-hiv-test/Help_Treat_HIV_icon.svg"
                  alt=""
                />
                <h3>
                  How to Treat HIV
                </h3>
                <p className="description">
                  Although there is no cure, people living with HIV may be able to live a longer and healthier life with proper care and taking HIV treatment as prescribed.
                </p>
                <p className="description">
                  A major goal of HIV treatment is getting your viral load to undetectable and keeping 
                  it there. Undetectable means that there is so little virus in the blood that 
                  a lab test can't measure it. Current research shows that taking HIV treatment 
                  as prescribed and staying undetectable for at least 6 months prevents transmitting HIV to others 
                  through sex. Other ways to say that are undetectable=untransmittable (U=U) or treatment as prevention (TasP).
                </p>
                <p className="description">
                  Starting treatment right away can have important health benefits. Talk to a healthcare professional about all your options—some treatments can even be started the same day as a&nbsp;diagnosis.
                </p>
                <a
                  target="_blank"
                  className="button transparent bdr-blue thin"
                  href="https://www.helpstopthevirus.com/hiv-treatment"
                >
                  LEARN MORE
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )

}

export default KnowYourOptions;