import * as React from 'react';
import '../assets/sass/app.scss';
import Layout from '../components/global/Layout.js';
import { HeroMedia, Cta } from '../components/after-an-hiv-test/HeroContent.js'
import KnowYourOptions from "../components/after-an-hiv-test/KnowYourOptions";
import UnderstandingYourResults from "../components/after-an-hiv-test/UnderstandingYourResults";
import FooterCalloutBar from "../components/global/FooterCalloutBar.js";
import scrollToElement from '../components/global/ScrollToElement';
import Header from '../components/global/Header.js';

const AfterAnHIVTest = () => {
  return (
    <>
      <Header page="after-an-hiv-test" scrollToElement={scrollToElement} />
      <Layout
        page="after-an-hiv-test"
        heroMedia={<HeroMedia />}
        CTA={Cta}
        socialHasBar={true}
      >
        <UnderstandingYourResults />
        <KnowYourOptions />
        <FooterCalloutBar/>
      </Layout>
    </>
  )
}

export default AfterAnHIVTest
