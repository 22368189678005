import * as React from 'react';
import $ from "jquery";

const NegativeSlide1 = () => {
  return (
    <div>
      <img src="/images/after-an-hiv-test/Self_Care_icon.svg" alt="" />
      <p>
        HIV testing is an important and normal part of self-care and sexual health.
      </p>
    </div>
  )
};

/*const NegativeSlide2 = () => {
  return (
    <div>
      <img src="/images/after-an-hiv-test/chat-bubble.svg" alt="chat bubble icon" />
      <p>
        Share your results with your partner. By knowing each other’s HIV status, you can take steps to stay healthy.
      </p>
    </div>
  )
};*/

const NegativeSlide3 = () => {
  return (
    <div>
      <img src="/images/after-an-hiv-test/Talk_With_HCP_icon.svg" alt="" />
      <p>
        Talk with a healthcare professional. Together you can find out what prevention options are right for you.
      </p>
    </div>
  )
}

const PositiveSlide1 = () => {
  return (
    <div>
      <img src="/images/after-an-hiv-test/As_Soon_As_Possible_icon.svg" alt="" />
      <p>
        Talk with a healthcare professional as soon as possible. They can help guide you to your next step, which could include a follow-up test to confirm your result.
      </p>
    </div>
  )
};

const PositiveSlide2 = () => {
  return (
    <div>
      <img src="/images/after-an-hiv-test/Help_Treat_HIV_icon.svg" alt="" />
      <p>
        Research shows starting HIV treatment as soon as possible—even the same day as diagnosis—could offer important health benefits. Talk to your healthcare professional to see which option is right for you.
      </p>
    </div>
  )
};

const PositiveSlide3 = () => {
  return (
    <div>
      <img src="/images/after-an-hiv-test/HIV_Medication_icon.svg" alt="" />
      <p>
        Take your HIV medicine as prescribed. Although there is no cure, with proper treatment and care, you could live a longer, healthier life.
      </p>
    </div>
  )
};

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slidePosition: null,
    }
  }
  componentDidMount() {
    // Hack to enable mobile swiping
    $('.dot.active').trigger('click');

    const checkitem = () => {
      if ($(`#${this.props.id} .carousel-inner .carousel-item:first`).hasClass('active')) {
        this.setState({ slidePosition: 'first' })
      } else if ($(`#${this.props.id} .carousel-inner .carousel-item:last`).hasClass('active')) {
        this.setState({ slidePosition: 'last' })
      } else {
        this.setState({ slidePosition: 'middle' })
      }
    }
    checkitem();
    const carousel = document.getElementById(this.props.id);
    carousel.addEventListener('slid.bs.carousel', checkitem)
  }
  render() {
    return (
      <div
        id={this.props.id}
        className="carousel slide d-block d-lg-none"
        data-bs-ride="carousel"
        data-bs-interval="false"
      >
        <div className="carousel-inner">
          {this.props.slides.map((slide, index) => {
            return (
              <div className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                {slide}
              </div>
            )
          })}
        </div>

        <div className="nav">
          <button className={`carousel-control-prev ${this.state.slidePosition === 'first' ? 'hide' : 'show'}`} type="button" data-bs-target={`#${this.props.id}`} data-bs-slide="prev">
            <img className="back" src="/images/global/triangle-blue.svg" />
            Back
          </button>
          <div className="carousel-indicators">

            {this.props.slides.map((slide, index) => {
              return (
                <button
                  className={`dot ${index === 0 ? 'active' : ''}`}
                  type="button"
                  data-bs-target={`#${this.props.id}`}
                  data-bs-slide-to={index}
                  aria-current={`${index === 0 ? 'true' : ''}`}
                  aria-label={`Slide ${index + 1}`} />
              )
            })}

          </div>
          <button className={`carousel-control-next ${this.state.slidePosition === 'last' ? 'hide' : 'show'}`} type="button" data-bs-target={`#${this.props.id}`} data-bs-slide="next">
            Next
            <img className="next" src="/images/global/triangle-blue.svg" />
          </button>
        </div>
      </div>
    )
  }
}

const UnderstandingYourResults = () => {
  return (
    <section id="nav-anchor-understanding-your-results" className="understanding-your-results container-fluid bkg-white">
      <div className="container px-0">
        <div className="row">
          <div className="col">
            <h2>
              Understanding <br className="d-block d-lg-none" />Your Results
            </h2>
            <p className="intro">
              Your HIV status is powerful information that can keep you and the people
              around you healthy. That's why choosing to share your result, encouraging your partner
              to get tested, and being proactive about your sexual health are so important. Just keep in mind some 
              states may have <a 
                className="opens-interstitial" 
                data-interstitial-type="gilead"
                href="https://www.cdc.gov/hiv/policies/law/states/exposure.html">
                  partner notification policies
                </a>, which means if you test 
              positive for HIV, you may be obligated to tell your partner(s).
            </p>
            <h3>
              Negative Result
            </h3>
            <p>
              Testing negative for HIV is just the beginning.
            </p>
            <div className="d-none d-lg-flex slides negative">
              <NegativeSlide1 />
              <NegativeSlide3 />
            </div>
            <Carousel slides={[<NegativeSlide1 />, <NegativeSlide3 />]} id="carousel-understanding-your-results-negative" />
            <h3>
              Positive Result
            </h3>
            <p className="summary">
              Getting a positive result can be life changing. But you're not alone. Healthcare
              and social service providers can help you deal with the news and
              make sure you find and get the HIV treatment and care you need.
            </p>
            <div className="d-none d-lg-flex slides positive">
              <PositiveSlide1 />
              <PositiveSlide2 />
              <PositiveSlide3 />
            </div>
            <Carousel slides={[<PositiveSlide1 />, <PositiveSlide2 />, <PositiveSlide3 />]} id="carousel-understanding-your-results-positive" />
          </div>
        </div>
      </div>
    </section>
  )

}

export default UnderstandingYourResults;